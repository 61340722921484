





















import Vue, { PropOptions } from 'vue';

export type FooterColumn = Blok & {
  title: string;
  links: BlokFieldLink[];
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<FooterColumn>,
  },
  data() {
    return { isOpen: false };
  },
});
